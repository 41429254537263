import useSettings from "app/hooks/useSettings";

export default function MatxLogo({ className }) {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 32 32"
      width="32px"
      height="32px"
      fill={theme.palette.primary.light}>
        <path d="M 15 2 L 15 5 L 8 5 L 8 6 C 8 17.711111 3.0136719 23.982422 3.0136719 23.982422 L 3.0351562 24 L 3 24 L 3 26 L 22 26 L 22 24 L 5.3945312 24 C 6.3603087 22.418959 7.952243 19.23188 9 14.6875 L 9 15 L 20 15 L 20 13 L 9.2773438 13 C 9.3884813 12.393761 9.3988261 11.648349 9.484375 11 L 20 11 L 20 9 L 9.6894531 9 C 9.7444838 8.3102614 9.8769076 7.7304147 9.8964844 7 L 22.097656 7 C 22.393589 18.610421 27.185547 25.582031 27.185547 25.582031 L 28.814453 24.417969 C 28.814453 24.417969 24 17.722222 24 6 L 24 5 L 17 5 L 17 2 L 15 2 z M 15.041016 27.304688 C 14.423995 29.401649 13.222656 30.898436 13.222656 30.898438 L 14.777344 32.15625 C 14.777344 32.15625 16.216005 30.394179 16.958984 27.869141 L 15.041016 27.304688 z"/>
    </svg>
  );
}
