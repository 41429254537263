import apiClient from 'app/utils/api';

export const FETCH_TRADING_HISTORY = 'FETCH_TRADING_HISTORY';
export const FETCH_PROFIT_HISTORY = 'FETCH_PROFIT_HISTORY';
export const FETCH_TOTAL_EARNINGS = 'FETCH_TOTAL_EARNINGS';
export const FETCH_PORTFOLIO_VALUE = 'FETCH_PORTFOLIO_VALUE';
export const FETCH_AVERAGE_PNL = 'FETCH_AVERAGE_PNL'
export const FETCH_RECENT_TRADES = 'FETCH_RECENT_TRADES';
export const FETCH_DAILY_PNL_HISTORY = 'FETCH_DAILY_PNL_HISTORY';
export const FETCH_PROFIT_PER_PAIR = 'FETCH_PROFIT_PER_PAIR';
export const FETCH_GOOD_BAD_DAYS = 'FETCH_GOOD_BAD_DAYS';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchTradingHistory = (ticker, errors, setErrors) => async (dispatch) => {
  try {
    const response = await apiClient.get(`${BASE_URL}/api/v1/gui/trading-history${ticker ? '?pair=' + ticker : ''}`);
    setErrors({ ...errors, tradingHistory: false });
    dispatch({ type: FETCH_TRADING_HISTORY, payload: response.data });
  } catch (error) {
    console.error('Error fetching trading history:', error);
    setErrors({ ...errors, tradingHistory: true });
  }
};

export const fetchProfitHistory = (ticker, errors, setErrors) => async (dispatch) => {
  try {
    const response = await apiClient.get(`${BASE_URL}/api/v1/gui/profit-history${ticker ? '?pair=' + ticker : ''}`);
    setErrors({ ...errors, profitHistory: false });
    dispatch({ type: FETCH_PROFIT_HISTORY, payload: response.data });
  } catch (error) {
    console.error('Error fetching profit history:', error);
    setErrors({ ...errors, profitHistory: true });
  }
};

export const fetchTotalEarnings = (ticker, errors, setErrors) => async (dispatch) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/api/v1/gui/total-earnings${ticker ? '?pair=' + ticker : ''}`);
      setErrors({ ...errors, totalEarnings: false });
      dispatch({ type: FETCH_TOTAL_EARNINGS, payload: response.data });
    } catch (error) {
      console.error('Error fetching total earnings:', error);
      setErrors({ ...errors, totalEarnings: true });
    }
};
  
export const fetchPortfolioValue = (ticker, errors, setErrors) => async (dispatch) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/api/v1/gui/portfolio-value${ticker ? '?pair=' + ticker : ''}`);
      setErrors({ ...errors, portfolioValue: false });
      dispatch({ type: FETCH_PORTFOLIO_VALUE, payload: response.data });
    } catch (error) {
      console.error('Error fetching portfolio value:', error);
      setErrors({ ...errors, portfolioValue: true });
    }
};
  
export const fetchAveragePnL = (ticker, errors, setErrors) => async (dispatch) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/api/v1/gui/average-pnl${ticker ? '?pair=' + ticker : ''}`);
      setErrors({ ...errors, averagePnL: false });
      dispatch({ type: FETCH_AVERAGE_PNL, payload: response.data });
    } catch (error) {
      console.error('Error fetching average PnL:', error);
      setErrors({ ...errors, averagePnL: true });
    }
};

export const fetchRecentTrades = (ticker, errors, setErrors) => async (dispatch) => {
  try {
    const response = await apiClient.get(`${BASE_URL}/api/v1/gui/recent-trades${ticker ? '?pair=' + ticker : ''}`);
    setErrors({ ...errors, recentTrades: false });
    dispatch({ type: FETCH_RECENT_TRADES, payload: response.data });
  } catch (error) {
    console.error('Error fetching recent trades:', error);
    setErrors({ ...errors, recentTrades: true });
  }
};

export const fetchDailyPnLHistory = (ticker, errors, setErrors) => async (dispatch) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/api/v1/gui/daily-pnl-history${ticker ? '?pair=' + ticker : ''}`);
      setErrors({ ...errors, dailyPnLHistory: false });
      dispatch({ type: FETCH_DAILY_PNL_HISTORY, payload: response.data });
    } catch (error) {
      console.error('Error fetching daily PnL history:', error);
      setErrors({ ...errors, dailyPnLHistory: true });
    }
};

export const fetchProfitPerPair = (ticker, errors, setErrors) => async (dispatch) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/api/v1/gui/profit-per-pair${ticker ? '?pair=' + ticker : ''}`);
      setErrors({ ...errors, profitPerPair: false });
      dispatch({ type: FETCH_PROFIT_PER_PAIR, payload: response.data });
    } catch (error) {
      console.error('Error fetching profit per pair:', error);
      setErrors({ ...errors, profitPerPair: true });
    }
};

export const fetchGoodBadDays = (ticker, errors, setErrors) => async (dispatch) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/api/v1/gui/good-bad-days${ticker ? '?pair=' + ticker : ''}`);
      setErrors({ ...errors, goodBadDays: false });
      dispatch({ type: FETCH_GOOD_BAD_DAYS, payload: response.data });
    } catch (error) {
      console.error('Error fetching good bad days:', error);
      setErrors({ ...errors, goodBadDays: true });
    }
};