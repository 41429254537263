import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000000,
});

export default apiClient;
