import {
    FETCH_TRADING_HISTORY,
    FETCH_PROFIT_HISTORY,
    FETCH_TOTAL_EARNINGS,
    FETCH_PORTFOLIO_VALUE,
    FETCH_AVERAGE_PNL,
    FETCH_RECENT_TRADES,
    FETCH_DAILY_PNL_HISTORY,
    FETCH_PROFIT_PER_PAIR,
    FETCH_GOOD_BAD_DAYS,
} from "../actions/dataActions";

const initialState = {
    tradingHistory: {
        history: [100, 200, 150, 300, 250, 400, 350], // Example trading values
        dates: ["2023-10-01", "2023-10-02", "2023-10-03", "2023-10-04", "2023-10-05", "2023-10-06", "2023-10-07"] // Example dates
    },
    profitHistory: {
        history: [10, 20, 15, 30, 25, 40, 35], // Example profit values
        dates: ["2023-10-01", "2023-10-02", "2023-10-03", "2023-10-04", "2023-10-05", "2023-10-06", "2023-10-07"] // Example dates
    },
    totalEarnings: {
        earnings: 1000, // Example total earnings
        percentage: 10 // Example percentage change
    },
    averagePnL: {
        average: 50, // Example average PnL
        percentage: 5 // Example percentage change
    },
    portfolioValue: {
        pairs: [
            { name: "BTC", value: 100 }, // Example pair values
            { name: "ETH", value: 150 },
            { name: "LTC", value: 20 }
        ]
    },
    profitPerPair: {
        result: [
            ["BTC/USD", 500], // Example profit per pair
            ["ETH/USD", 300],
            ["LTC/USD", 200]
        ]
    },
    recentTrades: {
        history: [
            {
                "time": "2024-09-13 19:49:32",
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "type": "buy",
                "orderPrice": 0.010903,
                "amount": 1374,
                "total": 14.995702722,
                "pnl": 0,
                "cumulative_pnl": 0,
                "cumulative_pnl_percent": 0
              },
              {
                "time": "2024-09-13 19:43:03",
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "type": "buy",
                "orderPrice": 0.010931,
                "amount": 1370,
                "total": 14.99044547,
                "pnl": 0,
                "cumulative_pnl": 0,
                "cumulative_pnl_percent": 0
              },
              {
                "time": "2024-09-13 19:12:18",
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "type": "buy",
                "orderPrice": 0.010964,
                "amount": 1367,
                "total": 15.002775788,
                "pnl": 0,
                "cumulative_pnl": 0,
                "cumulative_pnl_percent": 0
              },
              {
                "time": "2024-09-13 18:59:05",
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "type": "buy",
                "orderPrice": 0.010989,
                "amount": 1365,
                "total": 15.014984985,
                "pnl": 0,
                "cumulative_pnl": 0,
                "cumulative_pnl_percent": 0
              },
              {
                "time": "2024-09-13 18:54:37",
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "type": "sell",
                "orderPrice": 0.011003,
                "amount": 1361,
                "total": 14.960107917,
                "pnl": 0.0315726183,
                "cumulative_pnl": 0.0315726183,
                "cumulative_pnl_percent": 4.21170310946625
              },
              {
                "time": "2024-09-13 18:53:13",
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "type": "sell",
                "orderPrice": 0.01102,
                "amount": 1362,
                "total": 14.99423076,
                "pnl": 0.0876428338,
                "cumulative_pnl": 0.1192154521,
                "cumulative_pnl_percent": 13.252309290687
              },
        ]
    },
    dailyPnLHistory: {
        history: [
            {
                "date": "2024-09-13",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "daily_pnl": 2.6387755072,
                "cumulative_pnl": 436.448398006099,
                "cumulative_pnl_percent": 0.36043989352007
              },
              {
                "date": "2024-09-12",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "daily_pnl": 1.574693531,
                "cumulative_pnl": 433.809622498899,
                "cumulative_pnl_percent": 0.360672495315048
              },
              {
                "date": "2024-09-08",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "daily_pnl": 10.1051162773,
                "cumulative_pnl": 432.2349289679,
                "cumulative_pnl_percent": 0.36293782062607
              },
              {
                "date": "2024-09-07",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "daily_pnl": 23.6207329142,
                "cumulative_pnl": 422.1298126906,
                "cumulative_pnl_percent": 0.357377453362798
              },
              {
                "date": "2024-09-06",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "daily_pnl": 4.8284674635,
                "cumulative_pnl": 398.5090797764,
                "cumulative_pnl_percent": 0.341981418048332
              },
              {
                "date": "2024-09-05",
                "exchange": "kucoin",
                "pair": "DOGE-USDT",
                "daily_pnl": 1.1650068371,
                "cumulative_pnl": 393.6806123129,
                "cumulative_pnl_percent": 0.34037882504489
              },
        ]
    },
    goodBadDays: {
        week: {
            goodDays: 3, // Example good days in a week
            badDays: 4 // Example bad days in a week
        },
        month: {
            goodDays: 15, // Example good days in a month
            badDays: 10 // Example bad days in a month
        },
        forever: {
            goodDays: 100, // Example good days forever
            badDays: 20 // Example bad days forever
        }
    }
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRADING_HISTORY:
            return { ...state, tradingHistory: action.payload };
        case FETCH_PROFIT_HISTORY:
            return { ...state, profitHistory: action.payload };
        case FETCH_TOTAL_EARNINGS:
            return { ...state, totalEarnings: action.payload };
        case FETCH_PORTFOLIO_VALUE:
            return { ...state, portfolioValue: action.payload };
        case FETCH_AVERAGE_PNL:
            return { ...state, averagePnL: action.payload };
        case FETCH_RECENT_TRADES:
            return { ...state, recentTrades: action.payload };
        case FETCH_DAILY_PNL_HISTORY:
            return { ...state, dailyPnLHistory: action.payload };
        case FETCH_PROFIT_PER_PAIR:
            return { ...state, profitPerPair: action.payload };
        case FETCH_GOOD_BAD_DAYS:
            return { ...state, goodBadDays: action.payload };
        default:
            return state;
    }
};

export default dataReducer;
